import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import IconButton from "./icon-button";
import SvgCross from "../icons/cross";
import ToggleButton from "../components/toggle-button";
import { osName, CustomView } from "react-device-detect";
const wrapperIntro = keyframes`
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }
`;

const backdropIntro = keyframes`
  0% {
    opacity: 0;
  }
`;

const AboutModalWrapper = styled.div`
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 8rem;
`;

const Wrapper = styled.div`
  background-color: var(--background);
  height: 100%;
  z-index: 120;
  position: relative;
  animation: 0.3s ${wrapperIntro};
`;

const Content = styled.div`
  max-width: calc(var(--pageMaxWidth) / 2);
  padding: 2rem var(--pagePaddingX) 4rem var(--pagePaddingX);
  margin: 0 auto;
`;

const CloseSection = styled.div`
  .closeIcon {
    margin-left: auto;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Backdrop = styled.div`
  height: 100%;
  background-color: var(--backdrop);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 110;
  animation: 0.3s ${backdropIntro};
`;

const AboutModal = ({ setAboutModalIsOpen }) => {
  return (
    <AboutModalWrapper>
      <Backdrop onClick={() => setAboutModalIsOpen(false)} />
      <Wrapper>
        <Content>
          <CloseSection>
            <CustomView
              condition={
                osName === "Android" &&
                !window.matchMedia("(display-mode: standalone)").matches
              }
            >
              <ToggleButton
                style={{ padding: "0.75rem" }}
                onClick={() => window.open("https://app.matters.seelay.in")}
              >
                Download App
              </ToggleButton>
            </CustomView>

            <IconButton
              className="closeIcon"
              icon={<SvgCross />}
              onClick={() => setAboutModalIsOpen(false)}
            />
          </CloseSection>

          <h1>About Matters.</h1>
          <p>
            Less noise, more focus. Matters is a task manager that visually
            highlights what matters most to you.
          </p>
          <br />
          <h2>No account needed</h2>
          <p>
            Your tasks are stored in your browser (localStorage). Your data will
            persist until your computer explodes or you delete your browser.
          </p>
          <br />
          <hr />
          <br />

          <p id="attribution">
            Made with ❤ by{" "}
            <a
              id="seelaylogo"
              href="https://www.seelay.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              SEELAY
            </a>
          </p>
        </Content>
      </Wrapper>
    </AboutModalWrapper>
  );
};

export default AboutModal;
